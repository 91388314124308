export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-analytics',
    permissions: ['dashboard'],
  },
  {
    title: 'Second Page',
    route: 'second-page',
    icon: 'FileIcon',
  },
  {
    header: 'Roles & Users',
    permissions: ['users', 'roles'],
  },
  {
    title: 'Roles',
    icon: 'UsersIcon',
    route: 'roles',
    permissions: ['roles'],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'users',
    permissions: ['users'],
  },
  {
    header: 'Countries',
    permissions: ['countries'],
  },
  {
    title: 'Countries',
    icon: 'MapIcon',
    route: 'countries',
    permissions: ['countries'],
  },
]
